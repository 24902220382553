import React, { ChangeEventHandler } from 'react';
import { LocalizationContext, Translations } from 'react-components/LocalizationContext/LocalizationContext';
import Schedule, { Sort } from 'models/Schedule';
import PricesStore, { HourOfPower } from 'stores/PricesStore';
import './EnergyScreen.scss';

const SECONDS_TIL_MIDNIGHT = 24 * 3600;

enum Mode {
    DELAY = 'delay',
    PRECISE = 'precise',
}

interface State {
    runTimeHours: number;
    runTimeMinutes: number;
    powerConsumption: number;
    isLoaded: boolean;
    isError: boolean;
    hoursOfPower: HourOfPower[];
    mode: Mode,
    sort: Sort,
}

export default class EnergyScreen extends React.Component<{}, State> {
    state: State = {
        runTimeHours: 1,
        runTimeMinutes: 30,
        powerConsumption: 1800,
        isLoaded: false,
        isError: false,
        hoursOfPower: [],
        mode: Mode.DELAY,
        sort: Sort.PRICE,
    }

    componentDidMount() {
        PricesStore.load()
            .then((hoursOfPower) => {
                this.setState({
                    isLoaded: true,
                    isError: false,
                    hoursOfPower: hoursOfPower,
                });
            })
            .catch(() => {
                this.setState({
                    isLoaded: true,
                    isError: true,
                });
            });
    }

    onRunTimeHourChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { value } = event.target;

        this.setState({
            runTimeHours: parseInt(value || '0', 10),
        });
    };

    onRunTimeMinuteChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { value } = event.target;

        this.setState({
            runTimeMinutes: parseInt(value || '0', 10),
        });
    };

    onPowerConsumptionChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { value } = event.target;

        // Do not calculate with 0 power
        if (!value) {
            return;
        }

        this.setState({
            powerConsumption: parseInt(value, 10),
        });
    };

    onModeChange: ChangeEventHandler<HTMLOptionElement> = (event) => {
        const { value } = event.target;

        this.setState({
            mode: value as Mode,
        });
    };

    onSortChange = (sort: Sort) => {
        this.setState({
            sort: sort,
        });
    }

    formatHour(seconds: number, keepDay: boolean): string {
        if (!keepDay && seconds >= SECONDS_TIL_MIDNIGHT) {
            seconds -= SECONDS_TIL_MIDNIGHT;
        }
        const hour = ('' + Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = ('' + Math.floor(seconds / 60) % 60).padStart(2, '0');

        return `${hour}:${minutes}`;
    }

    get runTimeSeconds(): number {
        const { runTimeHours, runTimeMinutes } = this.state;
        return runTimeHours * 3600 + runTimeMinutes * 60;
    }

    get currentTime(): number {
        const now = new Date();
        return now.getHours() * 3600 + now.getMinutes() * 60;
    }

    get currentPrice(): number | undefined {
        const { hoursOfPower } = this.state;
        const currentTime = this.currentTime;

        const current = hoursOfPower.find((hourOfPower) => {
            const { startTime, endTime } = hourOfPower;
            return startTime <= currentTime && endTime >= currentTime;
        });

        return current?.priceEurInMwh;
    }

    getEnergyTable(t: Translations): JSX.Element {
        const { hoursOfPower, powerConsumption, mode, sort } = this.state;

        const schedule = new Schedule({
            hoursOfPower: hoursOfPower.filter((hourOfPower) => {
                return hourOfPower.endTime >= this.currentTime;
            }),
        });

        const powerConsumptionMw = powerConsumption / 1000 / 1000; // mega watts
        const cheapestStartTimes = mode === Mode.DELAY
            ? schedule.getCostForStartTimes(this.runTimeSeconds, this.currentTime)
            : schedule.getCheapestStartTimes(this.runTimeSeconds);
        const sortedStartTimes = Schedule.sort(Sort.PRICE, cheapestStartTimes);
        const bestPrice: number = cheapestStartTimes.length ? sortedStartTimes[0].cost : 0;
        const worstPrice: number = cheapestStartTimes.length ? sortedStartTimes[sortedStartTimes.length - 1].cost : 0;

        const header = (
            <tr>
                <th
                    colSpan={2}
                    className={sort === Sort.TIME ? 'active' : 'sortable'}
                    onClick={() => this.onSortChange(Sort.TIME)}
                >
                    {t.energy.table.time}
                </th>
                <th
                    className={sort === Sort.PRICE ? 'active' : 'sortable'}
                    onClick={() => this.onSortChange(Sort.PRICE)}
                >{t.energy.table.diff}</th>
                <th>{t.energy.table.cost}</th>
                <th>{t.energy.table.averageMwh}</th>
                <th>{t.energy.table.inTime}</th>
            </tr>
        );

        const rows = Schedule.sort(sort, cheapestStartTimes).map((hourlyCost) => {
            const { cost, startTime } = hourlyCost;
            const isToday = startTime < SECONDS_TIL_MIDNIGHT;
            const day = isToday ? t.energy.today : t.energy.tomorrow;
            const percent = cost === bestPrice
                ? 0
                : ((cost - bestPrice) / (worstPrice - bestPrice));
            const costInEur = Math.round(powerConsumptionMw * cost * 100) / 100;
            return (
                <tr>
                    <td>{day}</td>
                    <td>{this.formatHour(startTime, false)}</td>
                    <td><meter value={percent} color={'green'} optimum={0} low={0.5} high={0.75}/></td>
                    <td>{costInEur.toPrecision(2)}€</td>
                    <td>{Math.round(cost / this.runTimeSeconds * 3600)}</td>
                    <td>{this.formatHour(startTime - this.currentTime, true)}</td>
                </tr>
            );
        });

        return (
            <table id='energyTable'>
                <thead>{header}</thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }

    renderList(t: Translations): JSX.Element {
        const { runTimeHours, runTimeMinutes, powerConsumption, isLoaded, isError } = this.state;

        if (!isLoaded) {
            return <div>{t.energy.loading}...</div>;
        }

        if (isError) {
            return <div>{t.energy.error}</div>;
        }

        return (
            <>
                <h1>{t.energy.title}</h1>
                <div>
                    {t.energy.applianceRunTime}:
                    <input
                        className={'numberInput'}
                        type={'number'}
                        value={runTimeHours}
                        name={'hours'}
                        onChange={this.onRunTimeHourChange}
                        min={0}
                        max={24}
                    />h
                    <input
                        className={'numberInput'}
                        type={'number'}
                        value={runTimeMinutes}
                        name={'minutes'}
                        onChange={this.onRunTimeMinuteChange}
                        min={0}
                        max={59}
                        step={5}
                    />min
                </div>
                <div>
                    {t.energy.appliancePower}:
                    <input
                        className={'numberInput'}
                        type={'number'}
                        value={powerConsumption}
                        name={'power'}
                        onChange={this.onPowerConsumptionChange}
                        min={0}
                        step={50}
                    />w
                </div>
                <div>
                    {t.energy.currentPrice}: {this.currentPrice}€/Mwh
                </div>
                {this.getEnergyTable(t)}
            </>
        )
    }

    render() {
        return (
            <LocalizationContext.Consumer>
                {({ t }) => this.renderList(t)}
            </LocalizationContext.Consumer>
        );
    }
}