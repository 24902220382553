import React from 'react';
import { LocalizationContext } from 'react-components/LocalizationContext/LocalizationContext';
import CompanyLogo from '../assets/images/jt_software.png';

export default class AboutScreen extends React.Component
{
    render() {
        return (
            <LocalizationContext.Consumer>
                {({t}) => (
                    <div>
                        <h1>JT Software OÜ</h1>

                        <div id="contact-container" className="col-12">
                            <div className="col-1">&nbsp;</div>
                            <div className="col-5">
                                <ul className="light left">
                                    <li>JT SOFTWARE OÜ</li>
                                    <li>{t.about.regCode}: 12541706</li>
                                    <li>{t.about.bank}: AS SEB Pank</li>
                                    <li>{t.about.accountNum}: EE171010220224050224</li>
                                </ul>
                            </div>
                            <div className="col-5">
                                <ul className="light right">
                                    <li>{t.about.phone}: <a href="tel:+37256947855">+372 56947855</a></li>
                                    <li>{t.about.eMail}: <a href="mailto:jaak.tamre@gmail.com">jaak.tamre@gmail.com</a></li>
                                    <li>Tamme 1b</li>
                                    <li>Luige Alevik, Kiili Vald</li>
                                    <li>75404 Harjumaa</li>
                                    <li>{t.about.estonia}</li>
                                </ul>
                            </div>
                            <div className="col-1">&nbsp;</div>
                        </div>
                        <div id="logo-container-small" className="col-12">
                            <img id="logo-image" src={CompanyLogo} alt="Logo"/>
                        </div>
                    </div>)
                }
            </LocalizationContext.Consumer>
        );
    }
}
