import './App.css';
import Menu, { Page } from './react-components/Menu/Menu';
import React from 'react';
import HomeScreen from './screens/HomeScreen';
import AboutScreen from './screens/AboutScreen';
import PrivacyScreen from './screens/PrivacyScreen';
import SuperMoleScreen from './screens/SuperMoleScreen';
import { LocalizationProvider } from './react-components/LocalizationContext/LocalizationContext';
import EnergyScreen from 'screens/EnergyScreen';
import StreamPage from './screens/StreamPage';

interface State {
    activeMenuItem: Page
}

export default class App extends React.Component<any, State> {
    state: State = {
        activeMenuItem: Page.home,
    }

    selectMenuItem = (page: Page) => {
        this.setState({
            activeMenuItem: page,
        })
    }

    getMenu = (): JSX.Element | null => {
        if (this.state.activeMenuItem === Page.drone) {
            return null;
        }
        return (
            <Menu onSelectItem={this.selectMenuItem}/>
        );
    }

    getContentAndMenu(menu: JSX.Element | null, content: JSX.Element): JSX.Element
    {
        if (!menu) {
            return content;
        }

        return (
            <div className="grid">
                <div className="col-2">{menu}</div>
                <div className="col-8">{content}</div>
            </div>
        );
    }

    render() {
        let content = <div/>;

        switch (this.state.activeMenuItem) {
            case Page.home:
                content = <HomeScreen/>;
                break;
            case Page.superMole:
                content = <SuperMoleScreen/>;
                break;
            case Page.privacy:
                content = <PrivacyScreen/>;
                break;
            case Page.about:
                content = <AboutScreen/>;
                break;
            case Page.energy:
                content = <EnergyScreen/>;
                break;
            case Page.drone:
                content = <StreamPage streams={[
                    {
                        title: 'Droon 1',
                        src: 'https://player.castr.com/live_ca188110a40511efad2fd70b167882b2',
                    },
                ]}/>;
        }

        const menu = this.getMenu();

        return (
            <LocalizationProvider>
                <div className="App">
                    {this.getContentAndMenu(menu, content)}
                </div>
            </LocalizationProvider>
        );
    }
}