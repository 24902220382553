import React from 'react';
import './DifficultyIndicator.scss';

interface Props {
    difficulty: number,
}

export default function DifficultyIndicator({ difficulty }: Props) {
    let indicators = [];
    let fillColor = null;

    switch (difficulty) {
        case 1:
            fillColor = 'green';
            break;
        case 2:
            fillColor = 'green';
            break;
        case 3:
        default:
            fillColor = 'yellow';
            break;
        case 4:
            fillColor = 'orange';
            break;
        case 5:
            fillColor = 'red';
            break;
    }

    for (let i = 1; i <= 5; i++) {
        indicators.push(<div
            key={'i' + i}
            className={'diff-indicator-indicator'}
            style={{backgroundColor: difficulty < i ? 'transparent' : fillColor}}
        />);
    }

    return (
        <div className='diff-indicator-container'>
            { indicators }
        </div>
    );
}
