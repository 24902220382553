import translationsEng from './translationsEng';
import translationsEst from './translationsEst';

export enum Language {
    est = 'est',
    eng = 'eng',
}

const translations: any = {
    est: translationsEst,
    eng: translationsEng,
};

export default translations;
