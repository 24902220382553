const translationsEst = {
    menu: {
        home: 'Esileht',
        superMole: 'Super muti mäng',
        privacy: 'Privaatsus',
        about: 'Kontakt',
        energy: 'Energia kalkulaator',
    },
    superMole: {
        title: 'Super muti mäng',
        downloadAlt: 'Laadi all App Store`st',
        appIconAlt: 'Äpi ikoon',
        descriptionHeading: 'Kirjeldus',
        description: 'Super muti mängu idee sai alguse Mustamäe Elamus Spa bowlingu saalist,' +
            'kus oli vahva konnadele haamriga virutamise automaat (Whack-a-mole tüüpi).' +
            'Lapsele meeldis see niivõrd palju, et issi hakkas uurima kas sellist äppi ka on,' +
            'kuid need üksikud mis leidusid ei olnud eri põhjustel sobivad.' +
            'Seega issi otsustas lapsele ise teha super muti mängu.',
        goal: 'Mängu eesmärk on lihtne - kui mutt tuleb peidust välja, peab teda puutuma, et punkte (mutiraha) koguda. ' +
            'Kui lapselt sai küsitud, mis ta selle raha eest osta tahaks, siis tuli vastuseks üllatusmuna! ' +
            'Kogutud mutiraha eest saab osta üllatusmuna, kust seest tuleb välja juhuslik loom.',
        animalsHeading: 'Loomad',
        animals: 'Kokku on 5 erinevat ala, kus on sellele sobivad loomad. Loomadel on vastavalt haruldusele ka värvikoodid:',
        name: 'Nimi',
        score: 'Punktid',
        common: 'Tavaline',
        uncommon: 'Ebatavaline',
        rare: 'Haruldane',
        epic: 'Eepiline',
        legendary: 'Legendaarne',
        unique: 'Unikaalne',
        difficultyHeading: 'Raskusastmed',
        difficultyDesc: 'Eri vanuses lastele on ka erinevad kiirused mängimiseks:',
        reactionTime: 'Reageerimisaeg',
        shop: 'Pood',
        shopIntro1: 'Poest saab osta üllatusmuna, mis sõltuvalt alast läheb kallimaks. ' +
            'Munast tuleb üks loom valitud alast. Mida väärtuslikum on loom, seda väiksem võimalus seda saada. ' +
            'Olemasolevaid loomi on võimalik saada mitu korda, kuid iga kord väheneb antud looma tõenäosus 25%. ' +
            'Seega on võimalik saada ühte looma maksimaalselt 4 korda',
        stars: 'tähte',
        shopIntro2: 'Lukus loomale saab vajutada, et seda eraldi osta.',
        shopIntro3: 'Alade valiku all kuvatud progressiriba näitab kui palju on järgmise ala lahti lukustamiseni. ' +
            'Kui alast on lukus kuni 2 looma, siis avaneb järgmine ala. ' +
            'Kõiki alasid saab ka korraga lahti lukustada rakenduse-sisese ostuga ning see lukustab lahti ' +
            'kuni 6-l Apple family seadmel. ' +
            'Rakenduse-sisesed ostud on kaitstud lapsevanema lukuga ning laps ei saa seda ise lihtsasti teha.',
        screenShotImageAlt: 'Kuvatõmmis',
        feedback: 'Palun saatke tagasisidet AppStore`i, PlayStore`i või e-posti kaudu:',
    },
    privacy: {
        title: 'Privaatsuspoliitika',
        paragraph1: {
            section1: 'Ettevõte (JT Software OÜ) ei kogu personaalseid andmeid ega jaga neid kolmandatele osapooltele.',
            section2: '',
        },
        paragraph2: 'Ettevõte järgib GDPR nõudeid ja teavitab kasutajaid privaatsuspoliitika muutumisest.',
    },
    about: {
        regCode: 'Reg. kood',
        bank: 'Pank',
        accountNum: 'Konto nr',
        phone: 'Telefon',
        eMail: 'e-post',
        estonia: 'EESTI',
    },
    energy: {
        title: 'Energia kalkulaator',
        loading: 'Andmete laadimine',
        error: 'Viga andmete laadimisel!',
        today: 'Täna',
        tomorrow: 'Homme',
        applianceRunTime: 'Seadme tööaeg',
        appliancePower: 'Seadme võimsus',
        currentPrice: 'Hetke hind',
        table: {
            time: 'Aeg',
            diff: '%',
            cost: 'Maksumus',
            averageMwh: 'Keskmine €/Mwh',
            inTime: 'Aega alguseni',
        }
    }
}

export default translationsEst;