const translationsEng = {
    menu: {
        home: 'Home',
        superMole: 'Super Mole Game',
        privacy: 'Privacy Policy',
        about: 'About',
        energy: 'Energy Calculator',
    },
    superMole: {
        title: 'Super Mole Game',
        downloadAlt: 'Download on the App Store',
        appIconAlt: 'App Icon',
        descriptionHeading: 'Description',
        description: 'Super Mole Game idea started from a bowling alley with ' +
            'a fun game machine. It was the whack-a-mole kind, but with frogs. ' +
            'Our child loved the game and daddy looked for a similar app ' +
            'but those were not satisfactory, so we decided to create our own game.',
        goal: 'The goal is simple - when a mole reveals itself from a hole the player must tap it to gain points (mole money). ' +
            'We asked our child what would he like to buy with that money and the answer was - a Kinder Surprise! ' +
            'In our game you can use mole money to buy a suprise egg with a random animal inside.',
        animalsHeading: 'Animals',
        animals: 'There are 5 areas in total, each with corresponding animals. Animal rarities are color coded:',
        name: 'Name',
        score: 'Score',
        common: 'Common',
        uncommon: 'Uncommon',
        rare: 'Rare',
        epic: 'Epic',
        legendary: 'Legendary',
        unique: 'Unique',
        difficultyHeading: 'Difficulty Levels',
        difficultyDesc: 'Different game speeds are meant for children of various ages:',
        reactionTime: 'Reaction Time',
        shop: 'Shop',
        shopIntro1: 'You can buy a suprise egg for mole money, cost depending on the area. ' +
            'Tap the egg to open it and it will hatch into a random animal from the current area. ' +
            'Animal rarity determines the chances of each animal. ' +
            'You can get duplicates of animals, but each duplicate reduces the chance of that animal by 25%. ' +
            'Therefore it is possible to receive any animal up to 4 times',
        stars: 'stars',
        shopIntro2: 'Tap on the locked animal and you can purchase it for mole money.',
        shopIntro3: 'Next area is unlocked when the green progressbar under area selection is filled. ' +
            'Progressbar fills when only 2 animals are missing for the active area. ' +
            'You can unlock all areas immediately via in-app purchase which can be used for up to 6 Apple family devices. ' +
            'In-app purchases are protected by parental lock to avoid easy access for kids.',
        screenShotImageAlt: 'Screenshot',
        feedback: 'Please send feedback via AppStore, PlayStore or e-mail:',
    },
    privacy: {
        title: 'Privacy Policy',
        paragraph1: {
            section1: 'Our organization (JT Software OÜ) does not collect or track any personal data.',
            section2: '',
        },
        paragraph2: 'We follow GDPR compliance guide and will notify users if our privacy policy will change.',
    },
    about: {
        regCode: 'Reg. code',
        bank: 'Bank',
        accountNum: 'Account num',
        phone: 'Phone',
        eMail: 'e-mail',
        estonia: 'ESTONIA',
    },
    energy: {
        title: 'Energy Calculator',
        today: 'Today',
        loading: 'Loading data',
        error: 'Error loading data!',
        tomorrow: 'Tomorrow',
        applianceRunTime: 'Appliance Running Time',
        appliancePower: 'Appliance Power',
        currentPrice: 'Current Price',
        table: {
            time: 'Time',
            diff: '%',
            cost: 'Cost',
            averageMwh: 'Avg €/Mwh',
            inTime: 'Starts in',
        }
    }
}

export default translationsEng;