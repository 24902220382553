import React from 'react';
import CompanyLogo from '../assets/images/jt_software.png';

export default class HomeScreen extends React.Component<any, any>
{
    render() {
        return (
            <div id={'logo-container'}>
                <img id="logo-image" src={CompanyLogo} alt="Logo"/>
            </div>
        );
    }
}
