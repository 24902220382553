export interface HourOfPower {
    // Number of hour in the given day (00 - 23)
    hourOfDay: number;
    // MWh price
    priceEurInMwh: number;
    // Seconds from today's midnight til start of hour
    startTime: number;
    // Seconds from today's midnight til end of hour
    endTime: number;
}

interface PricesResponse {
    hoursOfPower: HourOfPower[],
    cachedAt: string,
}

export default class PricesStore {
    public static load(): Promise<HourOfPower[]> {
        return fetch("/energy/prices.php")
            .then(res => res.json())
            .then((result: PricesResponse) => result.hoursOfPower);
    }
}
