import React, { createContext, useState } from 'react';
import translations, { Language } from '../../translations';
import TranslationsEst from 'translationsEst';
import TranslationsEng from 'translationsEng';

export type Translations = typeof TranslationsEst | typeof TranslationsEng;

let currentLanguage: Language = getStoredLanguage();
let t: Translations = translations[currentLanguage];

/**
 * Read language from local storage
 */
export function getStoredLanguage(): Language
{
    const lang = localStorage.getItem('language');
    if (Object.values(Language).includes(lang as Language)) {
        return lang as Language;
    }

    return  Language.eng;
}

export const LocalizationContext = createContext({
    t,
    setAppLanguage: (language: Language) => {console.log('setAppLanguage', language)},
    appLanguage: currentLanguage as Language,
});

export const LocalizationProvider: React.FC = ({children}) => {
    // appLanguage is variable, setAppLanguage changes appLanguage value and triggers re-renders
    const [appLanguage, setAppLanguage] = useState(currentLanguage);
    t = translations[currentLanguage];

    const setLanguage = (language: Language) => {
        t = translations[language];
        setAppLanguage(language);
        localStorage.setItem('language', language);
        currentLanguage = language;
    };

    return (
        <LocalizationContext.Provider
            value={{
                t,
                setAppLanguage: setLanguage,
                appLanguage,
            }}>
            {children}
        </LocalizationContext.Provider>
    );
};