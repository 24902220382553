export type Animal = {
    id: string,
    area: AreaType, // keyof AreaType,
    rarity: Rarity, // keyof Rarity,
    image: NodeRequire,
}

export interface IRarity {
    multiplier: number, // score is multiplied with this number
    color: string, // rarity number and animals background is in this color
    randomScale: number, // this determines the chance of randomly receiving an animal of this rarity
    price: number, // this is the cost of an animal/egg of given rarity
}

export enum Rarity {
   common = 'common',
   uncommon = 'uncommon',
   rare = 'rare',
   epic = 'epic',
   legendary = 'legendary',
   unique = 'unique',
}

let rarityMap = new Map<Rarity, IRarity>();
rarityMap.set(Rarity.common, {
    multiplier: 1,
    color: '#fff',
    randomScale: 13, // 50%; 62% (lv2, lv1)
    price: 100,
});
rarityMap.set(Rarity.uncommon, {
    multiplier: 2,
    color: '#aaffab',
    randomScale: 8, // 50%; 31%; 38% (lv3, lv2, lv1)
    price: 250,
});
rarityMap.set(Rarity.rare, {
    multiplier: 3,
    color: '#aaebff',
    randomScale: 5, // 50%; 33%; 19% (lv4, lv3, lv2)
    price: 500,
});
rarityMap.set(Rarity.epic, {
    multiplier: 4,
    color: '#d5aaff',
    randomScale: 3, // 50%; 30%; 17% (lv5, lv4, lv3)
    price: 1000,
});
rarityMap.set(Rarity.legendary, {
    multiplier: 5,
    color: '#f1b359',
    randomScale: 2, // 33%; 20% (lv5, lv4)
    price: 2000,
});
rarityMap.set(Rarity.unique, {
    multiplier: 6,
    color: '#f15977',
    randomScale: 1, // 17% (lv5)
    price: 4000,
});

export const Rarities = rarityMap;

export enum AreaType {
    meadow = 'meadow',
    forest = 'forest',
    jungle = 'jungle',
    ocean = 'ocean',
    desert = 'desert',
}

export enum AnimalType {
    mole = 'mole',
}

export enum SCORE_RESULT {
    HIT = 'hit',
    MISS = 'miss',
}