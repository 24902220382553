import { Component } from 'react';
import { Language } from '../../translations';
import FlagEst from '../../assets/images/flags/est.jpg';
import FlagUsa from '../../assets/images/flags/usa.jpg';
import './Flag.scss';

interface Props {
    activeLang: Language,
    lang: Language,
    onPress: any,
}


export default class Flag extends Component<Props>
{
    image: any;

    componentDidMount() {
        const { lang } = this.props;

        switch (lang) {
            case 'eng':
                this.image = FlagUsa;
                break;
            case 'est':
            default:
                this.image = FlagEst;
                break;
        }
    }

    selectLanguage = () => {
        const {onPress, lang} = this.props;
        onPress(lang);
    }

    render() {
        const {activeLang, lang} = this.props;

        return (
            <span onClick={this.selectLanguage}>
                <img
                    src={this.image}
                    className={['language', activeLang === lang ? 'active' : ''].join(' ')}
                    alt={lang}
                />
            </span>
        );
    }
}