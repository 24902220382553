import { HourOfPower } from '../stores/PricesStore';

const HOUR_IN_SECONDS = 3600;

export enum Sort {
    PRICE = 'price',
    TIME = 'time',
}

interface Props {
    hoursOfPower: HourOfPower[];
}

export interface HourlyCost {
    // Running cost in euros if appliance power is 1 mwh
    cost: number;
    // Seconds from previous midnight
    startTime: number;
}

export type HourlyCosts = HourlyCost[];

export default class Schedule {
    hoursOfPower: HourOfPower[] = [];

    constructor(props: Props) {
        this.hoursOfPower = props.hoursOfPower;
    }

    /**
     * Cheapest price when starting on the hour
     * @param {number} runTimeInSeconds
     */
    getCostStartOnTheHour(runTimeInSeconds: number): HourlyCosts {
        const hourlyCosts: HourlyCosts = [];
        this.hoursOfPower.forEach((hourOfPower, index) => {
            let runtime = runTimeInSeconds;
            let offset = 0;
            let totalCost = 0;

            while (runtime > 0 && this.hoursOfPower[index + offset]) {
                const currentHourOfPower = this.hoursOfPower[index + offset];
                totalCost += currentHourOfPower.priceEurInMwh * Math.min(HOUR_IN_SECONDS, runtime) / HOUR_IN_SECONDS;
                runtime -= HOUR_IN_SECONDS;
                offset += 1;
            }

            if (runtime <= 0) {
                hourlyCosts.push({
                    cost: totalCost,
                    startTime: hourOfPower.startTime,
                } as HourlyCost);
            }
        });

        return hourlyCosts;
    }

    /**
     * Cheapest price when ending on the hour
     * @param {number} runTimeInSeconds
     */
    getCostEndOnTheHour(runTimeInSeconds: number): HourlyCosts {
        const hourlyCosts: HourlyCosts = [];
        this.hoursOfPower.forEach((hourOfPower, index) => {
            let runtime = runTimeInSeconds;
            let offset = 0;
            let totalCost = 0;

            while (runtime > 0 && this.hoursOfPower[index + offset]) {
                const currentHourOfPower = this.hoursOfPower[index + offset];
                totalCost += currentHourOfPower.priceEurInMwh * Math.min(1, runtime / 3600);
                runtime -= 3600; // 1 hour
                offset -= 1;
            }

            if (runtime <= 0 && totalCost) {
                const startTime = hourOfPower.startTime + 3600 - runTimeInSeconds;
                hourlyCosts.push({
                    cost: totalCost,
                    startTime: startTime,
                });
            }
        });

        return hourlyCosts;
    }

    /**
     * Cheapest price when ending on the hour
     * @param {number} runTimeInSeconds
     * @param {number} startTime        Seconds from midnight
     */
    getCostForStartTime(runTimeInSeconds: number, startTime: number): number | undefined {
        const endTime = startTime + runTimeInSeconds;
        let runtime = runTimeInSeconds;
        let totalCost = 0;

        this.hoursOfPower.forEach((hourOfPower) => {
            const { startTime: hourStart, endTime: hourEnd, priceEurInMwh } = hourOfPower;

            if (hourEnd <= startTime) {
                return; // hour is over before the appliance starts
            }

            if (hourStart >= endTime) {
                return; // hour is after appliance finished
            }

            // Appliance runs for the whole hour
            let duration = HOUR_IN_SECONDS;
            const startsThisHour = hourStart <= startTime;
            const endsThisHour = hourEnd >= endTime;

            if (startsThisHour && endsThisHour) {
                duration = runTimeInSeconds;
            } else if (startsThisHour) {
                duration = hourEnd - startTime;
            } else if (endsThisHour) {
                // Appliance ends this hour
                duration = endTime - hourStart;
            }

            runtime -= duration;
            totalCost += priceEurInMwh * duration / HOUR_IN_SECONDS;
        });

        return runtime <= 0 ? totalCost : undefined;
    }

    getCostForStartTimes(runTimeInSeconds: number, startTime: number): HourlyCosts {
        const hourlyCosts: HourlyCosts = [];

        this.hoursOfPower.forEach((hourOfPower) => {
            const { startTime: hourStart, endTime: hourEnd } = hourOfPower;
            if (hourEnd < startTime) {
                return;
            }

            // Add how many seconds have passed since full hour
            const costStartTime = hourStart + startTime % 3600;
            const cost = this.getCostForStartTime(runTimeInSeconds, costStartTime);
            if (cost) {
                hourlyCosts.push({
                    cost: cost,
                    startTime: costStartTime,
                });
            }
        });

        return hourlyCosts;
    }

    /**
     * All start times sorted by cost
     * @param runTimeInSeconds
     */
    getCheapestStartTimes(runTimeInSeconds: number): HourlyCosts {
        const costPerStartTime = this.getCostStartOnTheHour(runTimeInSeconds);
        const costPerEndTime = runTimeInSeconds % 3600 !== 0
            ? this.getCostEndOnTheHour(runTimeInSeconds)
            : [];

        return [
            ...costPerStartTime,
            ...costPerEndTime,
        ];
    }

    static sort(method: Sort, costs: HourlyCosts): HourlyCosts {
        switch (method) {
            case Sort.PRICE:
                return costs
                    .sort((a: HourlyCost, b: HourlyCost) => {
                        return a.cost - b.cost;
                    });
            case Sort.TIME:
                return costs
                    .sort((a: HourlyCost, b: HourlyCost) => {
                        return a.startTime - b.startTime;
                    });
        }
    }
}