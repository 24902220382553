import React from 'react';
import { LocalizationContext } from '../react-components/LocalizationContext/LocalizationContext';

export default class PrivacyScreen extends React.Component
{
    render() {
        return (
            <LocalizationContext.Consumer>
                {({t}) => (
                    <div id="main-container">
                        <h1>{t.privacy.title}</h1>
                        <p>
                            {t.privacy.paragraph1.section1}<br />
                            {t.privacy.paragraph1.section2}
                        </p>
                        <p>{t.privacy.paragraph2}</p>
                    </div>
                )}
            </LocalizationContext.Consumer>
        );
    }
}