import React from 'react';
import './StreamPage.scss';

export interface Stream {
    title: string,
    src: string,
}

export type Streams = Stream[];

export interface Props {
    streams: Streams;
}

export default class StreamPage extends React.Component<Props> {
    getVideoGrid = (): JSX.Element => {
        const { streams } = this.props;
        const frames: JSX.Element[] = streams.map((stream: Stream): JSX.Element => {
            const source: string = stream.src;
            return <iframe src={source} title={stream.title} allowFullScreen/>;
        });
        let className = 'video-grid';
        if (streams.length > 1) {
            className = `${className} cols-count-2`;
        }
        if (streams.length > 2) {
            className = `${className} rows-count-2`;
        }

        return (
            <div className={className}>
                {frames}
            </div>
        );
    }

    render() {
        return this.getVideoGrid();
    }
}