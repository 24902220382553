import React from 'react';
import { LocalizationContext } from 'react-components/LocalizationContext/LocalizationContext';
import { Language } from 'translations';
import SuperMoleIcon from '../assets/images/appstore.png';
import AppStoreImage from '../assets/images/download.svg';
import AppStoreImageEt from '../assets/images/downloadEE.svg';
import ScreenShotImageEst from '../assets/images/screenshotWebEst.png';
import ScreenShotImageEng from '../assets/images/screenshotWebEng.png';
import StarImage from '../assets/images/star.png';
import DifficultyIndicator from '../react-components/DifficultyIndicator/DifficultyIndicator';
import './SuperMoleScreen.scss';
import { IRarity, Rarities, Rarity } from 'Types';
import PlayStoreImage from '../assets/images/google-play-badge.png';
import PlayStoreImageEt from '../assets/images/google-play-badge-et.png';

export default class SuperMoleScreen extends React.Component {
    getRarityRow(t: any, rarity: Rarity) {
        const rarityObj: IRarity = Rarities.get(rarity) as IRarity;
        return (
            <tr>
                <td className={'rarity-cell'} style={{backgroundColor: rarityObj.color}}>
                    {t.superMole[rarity]}
                </td>
                <td>x{rarityObj.multiplier}</td>
            </tr>
        );
    }

    render() {
        return (
            <LocalizationContext.Consumer>
                {({t, appLanguage}) => (
                    <div>
                        <h1>{t.superMole.title}</h1>
                        <div>
                            <img id="appIconImage" src={SuperMoleIcon} alt={t.superMole.appIconAlt}/>
                        </div>
                        <div>
                            <> {/* href="https://apps.apple.com/ee/app/super-mole-game/id1575099645" */}
                                <img id="downloadAppStoreImg"
                                     className={appLanguage}
                                     src={appLanguage === Language.est ? AppStoreImageEt : AppStoreImage}
                                     alt={t.superMole.downloadAlt}
                                />
                            </>
                            <a href="https://play.google.com/store/apps/details?id=com.zebraf1.supermutimang">
                                <img id="downloadPlayStoreImg"
                                     src={appLanguage === Language.est ? PlayStoreImageEt : PlayStoreImage}
                                     alt={t.superMole.downloadAlt}
                                />
                            </a>
                        </div>
                        <div>
                            <h2>{t.superMole.descriptionHeading}</h2>
                            {t.superMole.description}
                        </div>
                        <div>
                            {t.superMole.goal}
                        </div>
                        <div>
                            <h2>{t.superMole.animalsHeading}</h2>
                            {t.superMole.animals}
                            <table className={'table-center'}>
                                <thead>
                                    <tr>
                                        <th>{t.superMole.name}</th>
                                        <th>{t.superMole.score}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.getRarityRow(t, Rarity.common)}
                                    {this.getRarityRow(t, Rarity.uncommon)}
                                    {this.getRarityRow(t, Rarity.rare)}
                                    {this.getRarityRow(t, Rarity.epic)}
                                    {this.getRarityRow(t, Rarity.legendary)}
                                    {this.getRarityRow(t, Rarity.unique)}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h2>{t.superMole.difficultyHeading}</h2>
                            {t.superMole.difficultyDesc}
                            <table className={'table-center'}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t.superMole.score}</th>
                                        <th>{t.superMole.reactionTime}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><DifficultyIndicator difficulty={1} /></td>
                                        <td>x3</td>
                                        <td>3s</td>
                                    </tr>
                                    <tr>
                                        <td><DifficultyIndicator difficulty={2} /></td>
                                        <td>x3</td>
                                        <td>2s</td>
                                    </tr>
                                    <tr>
                                        <td><DifficultyIndicator difficulty={3} /></td>
                                        <td>x3</td>
                                        <td>1.5s</td>
                                    </tr>
                                    <tr>
                                        <td><DifficultyIndicator difficulty={4} /></td>
                                        <td>x4</td>
                                        <td>0.9s</td>
                                    </tr>
                                    <tr>
                                        <td><DifficultyIndicator difficulty={5} /></td>
                                        <td>x5</td>
                                        <td>0.55s</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h2>{t.superMole.shop}</h2>
                            {t.superMole.shopIntro1} (3 {t.superMole.stars} <img style={{height: 16}} src={StarImage} alt={'*'}/>).
                            <br />
                            {t.superMole.shopIntro2}<br />
                            <br />
                            {t.superMole.shopIntro3}
                        </div>
                        <br />
                        <img id="appScreenshotImage"
                             src={appLanguage === Language.est ? ScreenShotImageEst : ScreenShotImageEng}
                             alt={t.superMole.screenShotImageAlt}
                        />
                        <br />
                        <br />
                        {t.superMole.feedback} <a href={'mailto: jaak.tamre@gmail.com'}>jaak.tamre@gmail.com</a><br />
                        <br />
                    </div>
                )}
            </LocalizationContext.Consumer>
        );
    }
}